import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import('../views/BlogView.vue')
  },
  {
    path: '/books',
    name: 'books',
    component: () => import('../views/BooksView.vue')
  },
  {
    path: '/corporatewellness',
    name: 'corporatewellness',
    component: () => import('../views/CorporateWellness.vue')
  },
  {
    path: '/friends',
    name: 'friends',
    component: () => import('../views/FriendsView.vue')
  },
  {
    path: '/healthprofessionals',
    name: 'healthprofessionals',
    component: () => import('../views/HealthProfessionals.vue')
  },
  {
    path: '/mentor',
    name: 'mentor',
    component: () => import('../views/MentorMentees.vue')
  },
  {
    path: '/parentschildren',
    name: 'parentschildren',
    component: () => import('../views/ParentsChildren.vue')
  },
  {
    path: '/selfhelp',
    name: 'selfhelp',
    component: () => import('../views/SelfHelp.vue'),
    meta: { scrollToTop: true }
  }
  
]


const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    } else {
      return { top: 0 };
    }
  }
})

export default router
