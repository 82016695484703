<template>
    <div class="hero-body">
        <div class="box has-text-centered">
				<a href="https://play.google.com/store/apps/details?id=com.stratinmotion.areyouok">
					<img class="is-inline-block"
						src="https://firebasestorage.googleapis.com/v0/b/sim-areyouok.appspot.com/o/GetItOnGooglePlay_Badge_Web_color_English.png?alt=media&token=82796d3d-5faa-4374-8e06-79864fd0709e" />
				</a>
                <div class="block"></div>
            <div class="is-size-4 has-text-centered pb-4">
                <b>AreYouOK</b> is an adaptable tool that can be used in the following scenarios.<br>
                Whatever the application, it comes with the same benefits: <b>Easy to record, Easy to Share</b>
            </div>
        </div>
        <div class="box">
            <div class="columns is-multiline block">
                <OneCard    bold="Friends" 
                            regular="Support each other's well-being. " 
                            textColor="#FFFFFF" 
                            backgroundColor="#003895"
                            imageUrl="https://areyouok.stratinmotion.com/thumb-friends"
                            router="/friends" />
                <OneCard    bold="Books" 
                            regular="Action teachings from books on habit formation and mindfulness. " 
                            textColor="#FFFFFF" 
                            backgroundColor="#003b95"
                            imageUrl="https://areyouok.stratinmotion.com/thumb-books"
                            router="/books" />
            </div>
            <div class="columns is-multiline block">
                <OneCard    bold="Parents/Children" 
                            regular="Track your child's well-being without being annoying. "
                            textColor="#FFFFFF" 
                            backgroundColor="#003895"
                            imageUrl="https://areyouok.stratinmotion.com/thumb-parents"
                            router="/parentschildren" />
                <OneCard    bold="Self Help"   
                            regular="Build micro habits, control addiction and stress. " 
                            textColor="#FFFFFF" 
                            backgroundColor="#003b95"
                            imageUrl="https://areyouok.stratinmotion.com/thumb-self"
                            router="/selfhelp" />
            </div>

            <div class="columns is-multiline">
                <OneCard    bold="Corporate Wellness" 
                            regular="Tools to promote frontline staff well-being and leadership training. " 
                            textColor="#000000" 
                            backgroundColor="#ffbb0e"
                            imageUrl="https://areyouok.stratinmotion.com/thumb-corporate"
                            router="/corporatewellness" />
                <OneCard    bold="Health Professionals" 
                            regular="Track your clients' performance of pre-set actions. " 
                            textColor="#000000" 
                            backgroundColor="#ffbb0e"
                            imageUrl="https://areyouok.stratinmotion.com/thumb-health"
                            router="/healthprofessionals" />
            </div>


            <div class="columns is-multiline">
                <OneCard    bold="Mentor/Mentees" 
                            regular="Make feedback easy, improve conversation. " 
                            textColor="#000000" 
                            backgroundColor="#ffbb0e"
                            imageUrl="https://areyouok.stratinmotion.com/thumb-mentor"
                            router="/mentor" />
                <OneCard    bold="Blog" 
                            regular="Continuously update source of readings and app benefits. " 
                            textColor="#000000" 
                            backgroundColor="#006ce4"
                            imageUrl="https://areyouok.stratinmotion.com/thumb-blog"
                            router="blog/" />
            </div>
            <div class="block has-text-centered">
				<a href="https://play.google.com/store/apps/details?id=com.stratinmotion.areyouok">
					<img class="is-inline-block"
						src="https://firebasestorage.googleapis.com/v0/b/sim-areyouok.appspot.com/o/GetItOnGooglePlay_Badge_Web_color_English.png?alt=media&token=82796d3d-5faa-4374-8e06-79864fd0709e" />
				</a>
        </div>
        </div>
    </div>
</template>

<script>
import OneCard from './OneCard.vue';


export default {
    name: 'CardsView',
    components: {
        OneCard
    }
}
</script>


<style scoped></style>