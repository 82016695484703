import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import { Plugin } from 'vue-responsive-video-background-player'



createApp(App).use(Plugin).use(router).use(Buefy).mount('#app')
