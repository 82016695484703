<template>
  <footer class="footer">
    <div class="content has-text-centered">
      <p>
        AreYouOK is a <a href="https://are-you-ok.web.app">Strategy in
          Motion</a> offering - 
        Contact us at
        <a href="mailto:support@stratinmotion.com">support@stratinmotion.com</a>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
    name: 'CurrentFooter',
    data() {
        return {
            currentYear: new Date().getFullYear()
        };
    }
};
</script>

<style scoped>
.footer {
    background-color: #f8f8f8;
    padding: 20px;
    text-align: center;
}

.container {
    max-width: 960px;
    margin: 0 auto;
}
</style>