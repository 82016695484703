<template>
  <section>
    <div class="hero is-large has-text-centered level">
      <video-background poster="https://areyouok.stratinmotion.com/poster-desktop"
        src="https://areyouok.stratinmotion.com/video-desktop.mp4"
        :sources="[
          {
            src: 'https://areyouok.stratinmotion.com/video-mobile.mp4',
            res: 900,
            autoplay: true
          },
          {
            src: 'https://areyouok.stratinmotion.com/video-mobile.mp4',
            res: 638,
            autoplay: true,
            poster: 'https://areyouok.stratinmotion.com/poster-mobile'
          }
        ]" style="max-height: 95%; height: 95vh;">

        <!-- <div class="columns level-item" >
  <div class="column level-item" style="color: white;">
 <a href="https://play.google.com/store/apps/details?id=com.stratinmotion.areyouok">
            <img class="level-item image"
              src="https://firebasestorage.googleapis.com/v0/b/sim-areyouok.appspot.com/o/GetItOnGooglePlay_Badge_Web_color_English.png?alt=media&token=82796d3d-5faa-4374-8e06-79864fd0709e" />
          </a>
  </div>
  <div class="column level-item" style="color: yellow;">
    <h1 class="is-size-1">Find out more</h1>
    </div>
  </div> -->
      </video-background>

    </div>
  </section>

</template>

<script>
export default {
  name: 'HelloWorld',
  data() {
    return {
      carousels: [
        { text: 'Home page', link: 'https://firebasestorage.googleapis.com/v0/b/sim-areyouok.appspot.com/o/screenshots%2FScreenshot_20240618-101302_AreYouOK.png?alt=media&token=8ed5d1d1-1a00-4247-91f3-f8ac204b8da7' },
        { text: 'Track key events', link: 'https://firebasestorage.googleapis.com/v0/b/sim-areyouok.appspot.com/o/screenshots%2FScreenshot_20240618-101407_AreYouOK.png?alt=media&token=e145e95d-d826-47d4-87c2-0974ad4f5a6c' },
        { text: 'Connect with others', link: 'https://firebasestorage.googleapis.com/v0/b/sim-areyouok.appspot.com/o/screenshots%2FScreenshot_20240618-101441_AreYouOK.png?alt=media&token=cc4d89fd-1200-48f3-a33d-4e7ea286e237' },
        { text: 'Monitor your progress', link: 'https://firebasestorage.googleapis.com/v0/b/sim-areyouok.appspot.com/o/screenshots%2FScreenshot_20240618-130615_AreYouOK.png?alt=media&token=bdacce77-ce04-49b7-adb7-6742b7346c39' },
        { text: 'Helpful FAQ', link: 'https://firebasestorage.googleapis.com/v0/b/sim-areyouok.appspot.com/o/screenshots%2FScreenshot_20240618-101447_AreYouOK.png?alt=media&token=f1b9d5a7-558e-403c-ad0c-51f437e9842f' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#carousel-text {
  position: absolute;
  top: 87%;
}

.single-line {
  white-space: nowrap;
}

.my-center {
  margin-left: auto;
  margin-right: auto;
}

#badge {
  max-width: 20%;
  position: absolute;
}

.white {
  color: white;
}

.yellow-text {
  color: #ffbb0e;
}

.signature-blue {
  background-color: #003b95;
}

.home {
  margin: 40px;
  align-items: center;
}

.embolden {
  font-size: large;
}

.image {
  margin: 40px;
  max-width: 50%;
}

.byline {
  align-items: center;
  font-size: 1.5em;
  font-weight: bold;
  width: 100%;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
