<template>
  <nav class="has-navbar-fixed-top">
    <div class="columns level">
      <div class="column">
        <div class="level-item">

          <router-link to="/">
            <div class="is-size-4 has-text-weight-bold level-item"> <img class="icon"
                src="https://firebasestorage.googleapis.com/v0/b/sim-areyouok.appspot.com/o/play_store.png?alt=media&token=23b252c9-81a9-4e4d-b10d-a4114a37512d"
                alt="Strategy in Motion icon" />AreYouOK</div>
          </router-link>
        </div>
      </div>
      <div class="column"></div>
      <div class="fill level-item">
        <b-breadcrumb align="is-right" separator="has-bullet-separator">
          <b-breadcrumb-item><a href="https://stratinmotion.com">
              Go to:&nbsp;<div class="is-size-5 has-text-weight-bold">Strategy in Motion</div>
            </a></b-breadcrumb-item>
        </b-breadcrumb>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'CurrentHeader',
  data() {
    return {
      title: 'My App',
      menuItems: [
        { id: 1, label: 'Home', url: '/' },
        { id: 2, label: 'About', url: '/about' },
        { id: 3, label: 'Contact', url: '/contact' }
      ]
    };
  }
};
</script>

<style scoped>
.nav {
  padding: 15px;
  border-bottom: 2px solid #000;
}

nav a {
  font-weight: bold;
  color: #000000 !important;
}

.signature-blue {
  color: #003b95;
}

nav a.router-link-exact-active {
  color: #ffbb0e;
}
</style>