<template>
  <CurrentHeader />

  <router-view />

  <CurrentFooter />
</template>

<script>
import CurrentHeader from './components/CurrentHeader.vue';
import CurrentFooter from './components/CurrentFooter.vue';

export default {
  components: {
    CurrentHeader,
    CurrentFooter
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.nav {
  padding: 15px;
  border-bottom: 2px solid #000;
}

nav a {
  font-weight: bold;
  color: #000000 !important;
}

.signature-blue {
  color: #003b95;
}

nav a.router-link-exact-active {
  color: #ffbb0e;
}

/*@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');*/

</style>
