<template>

    <div class="column m-1">
            <router-link :to="router" @click="$emit('scrollToTop')">
        <div class="box this-is-my-div" :style="{ backgroundColor: backgroundColor }">
            <div class="columns m-1">   
                    <figure class="column is-flex is-justify-content-center is-flex-direction-column image is-256x256">
                        <img class="is-rounded" :src="imageUrl" />
                    </figure>
                <div class="column is-three-quarters this-is-my-div">
                    <div class="is-size-3 has-text-weight-bold" :style="{ 'color': textColor}">
                        {{ bold }}
                    </div>

                    <div class="is-size-4 pb-4" :style="{ 'color': textColor}">
                        {{ regular }} <b>See more 👉</b>
                    </div>
                    <br>

                </div>
            </div>
        </div>
    </router-link>
    </div>
</template>

<script>
export default {
    name: 'OneCard',
    props: {
        bold: String,
        regular: String,
        backgroundColor: String,
        imageUrl: String,
        textColor: String,
        tag: String,
        router: {
            type: String,
            required: true
        } 
    }
}
</script>

<style scoped>
.this-is-my-div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
</style>