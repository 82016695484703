<template>
  <div class="home">
    <HelloWorld />
    <CardsView />
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import CardsView from '@/components/CardsView.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld,
    CardsView
  }
}
</script>
